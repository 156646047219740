<template>
  <div id="digital-flop">
    <div
      class="digital-flop-item"
      v-for="item in digitalFlopData"
      :key="item.title"
    >
      <div class="digital-flop-title">{{ item.title }}</div>
      <div class="digital-flop">
        <dv-digital-flop
          :config="item.number"
          style="width:100px;height:50px;"
        />
          <div class="unit">{{ item.unit }}</div>
      </div>
    </div>

    <dv-decoration-10 />
  </div>
</template>

<script>
export default {
  name: 'DigitalFlop',
  props:{
    boardData:Object
  },
  data () {
    return {
      digitalFlopData: []
    }
  },
  methods: {
    createData () {
      const { randomExtend } = this

      console.log(this.boardData)

      this.digitalFlopData = [
        {
          title: '市场线索',
          number: {
            number: [this.boardData.crm.leads_total],
            content: '{nt}',
            textAlign: 'center',
            style: {
              fill: '#4d99fc',
              fontWeight: 'bold'
            }
          },
          unit: ''
        },
        {
          title: '线索跟进',
          number: {
            number: [this.boardData.crm.follow_total],
            content: '{nt}',
            textAlign: 'center',
            style: {
              fill: '#f46827',
              fontWeight: 'bold'
            }
          },
          unit: ''
        },
        {
          title: '试听统计',
          number: {
            number: [this.boardData.crm.audition_total],
            content: '{nt}',
            textAlign: 'center',
            style: {
              fill: '#40faee',
              fontWeight: 'bold'
            }
          },
          unit: ''
        },
        {
          title: '排课统计',
          number: {
            number: [this.boardData.crm.course_total],
            content: '{nt}',
            textAlign: 'center',
            style: {
              fill: '#4d99fc',
              fontWeight: 'bold'
            }
          },
          unit: ''
        },
        {
          title: '到课学员',
          number: {
            number: [this.boardData.crm.attend_total],
            content: '{nt}',
            textAlign: 'center',
            style: {
              fill: '#f46827',
              fontWeight: 'bold'
            }
          },
          unit: ''
        },
        {
          title: '缺勤学员',
          number: {
            number: [this.boardData.crm.cancel_total],
            content: '{nt}',
            textAlign: 'center',
            style: {
              fill: '#40faee',
              fontWeight: 'bold'
            }
          },
          unit: ''
        },
        {
          title: '新签金额',
          number: {
            number: [this.boardData.crm.new_contract_total],
            content: '{nt}',
            textAlign: 'center',
            style: {
              fill: '#4d99fc',
              fontWeight: 'bold'
            }
          },
          unit: ''
        },
        {
          title: '续费金额',
          number: {
            number: [this.boardData.crm.extend_contract_total],
            content: '{nt}',
            textAlign: 'center',
            style: {
              fill: '#f46827',
              fontWeight: 'bold'
            }
          },
          unit: ''
        },
        {
          title: '合计金额',
          number: {
            number: [this.boardData.crm.contract_total],
            content: '{nt}',
            textAlign: 'center',
            style: {
              fill: '#40faee',
              fontWeight: 'bold'
            }
          },
          unit: ''
        }
      ]
    },
    randomExtend (minNum, maxNum) {
      if (arguments.length === 1) {
        return parseInt(Math.random() * minNum + 1, 10)
      } else {
        return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10)
      }
    }
  },
  mounted () {
    const { createData } = this

    createData()

    setInterval(createData, 30000)
  }
}
</script>

<style lang="less">
#digital-flop {
  position: relative;
  height: 15%;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(6, 30, 93, 0.5);

  .dv-decoration-10 {
    position: absolute;
    width: 95%;
    left: 2.5%;
    height: 5px;
    bottom: 0px;
  }

  .digital-flop-item {
    width: 11%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-left: 3px solid rgb(6, 30, 93);
    border-right: 3px solid rgb(6, 30, 93);
  }

  .digital-flop-title {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .digital-flop {
    display: flex;
  }

  .unit {
    margin-left: 10px;
    display: flex;
    align-items: flex-end;
    box-sizing: border-box;
    padding-bottom: 13px;
  }
}
</style>
