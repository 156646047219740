<template>
  <div id="ranking-board">
    <div class="ranking-board-title">校区签单金额排行</div>
    <dv-scroll-ranking-board :config="config" />
  </div>
</template>

<script>
export default {
  name: 'RankingBoard',
  props:{
    boardData:Object
  },
  data () {
    return {
      config: {
        data: [],
        rowNum: 15
      }
    }
  },
  watch:{
    boardData:function(newData){
      this.config ={rowNum: 15,data:newData.rankingBoard,valueFormatter ({ value }) {
    console.warn(arguments)
    const reverseNumber = (value + '').split('').reverse()
    let valueStr = ''

    while (reverseNumber.length) {
      const seg = reverseNumber.splice(0, 3).join('')
      valueStr += seg
      if (seg.length === 3) valueStr += ','
    }

    return valueStr.split('').reverse().join('')
  }} 
    }
  },
  mounted(){
    this.config ={rowNum: 15,data:this.boardData.rankingBoard,valueFormatter ({ value }) {
    console.warn(arguments)
    const reverseNumber = (value + '').split('').reverse()
    let valueStr = ''

    while (reverseNumber.length) {
      const seg = reverseNumber.splice(0, 3).join('')
      valueStr += seg
      if (seg.length === 3) valueStr += ','
    }

    return valueStr.split('').reverse().join('')
  }} 
  },
}
</script>

<style lang="less">
#ranking-board {
  width: 20%;
  box-shadow: 0 0 3px blue;
  display: flex;
  flex-direction: column;
  background-color: rgba(6, 30, 93, 0.5);
  border-top: 2px solid rgba(1, 153, 209, .5);
  box-sizing: border-box;
  padding: 0px 30px;
  .ranking-board-title {
    font-weight: bold;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 20px;
  }

  .dv-scroll-ranking-board {
    flex: 1;
  }
}
</style>
