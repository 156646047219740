<template>
  <div id="data-view">
    <dv-loading v-if="loading">Loading...</dv-loading>
    <div v-else class="host-body">
      <dv-full-screen-container>

        <top-header :boardData="boardData" />

        <div class="main-content">
          
          <digital-flop :boardData="boardData" />

          <div class="block-left-right-content">

            <ranking-board :boardData="boardData" />
            
            <!-- <ranking-course-board :boardData="boardData" /> -->

            <div class="block-top-bottom-content">

              <div class="block-top-content">

                <div id="rose-chart">
                  <BottomLeftChart />
                </div>

                <!-- <rose-chart :boardData="boardData" />

                <water-level-chart :boardData="boardData" /> -->

                <scroll-board :boardData="boardData" />

              </div>

              <cards :boardData="boardData"/>
            </div>
          </div>
        </div>
      </dv-full-screen-container>
    </div>
  </div>
</template>

<script>
import BottomLeftChart from "@/components/echart/bottom/bottomLeftChart";
import topHeader from './topHeader'
import digitalFlop from './digitalFlop'
import rankingBoard from './rankingBoard'
import rankingCourseBoard from './rankingCourseBoard'
import roseChart from './roseChart'
import waterLevelChart from './waterLevelChart'
import scrollBoard from './scrollBoard'
import cards from './cards'

export default {
  name: 'DataView',
  components: {
    topHeader,
    digitalFlop,
    rankingBoard,
    BottomLeftChart,
    rankingCourseBoard,
    roseChart,
    waterLevelChart,
    scrollBoard,
    cards
  },
  data () {
    return {
      loading:true,
      paramsData:{},
      boardData: {},
    }
  },
  mounted () {
    const { createData } = this
    createData()
    setInterval(createData, 30000)
  },
  methods: {
    async createData (){
        let res = await this.$store.dispatch('analysisBoardAction',{search:this.paramsData})
        this.boardData = res.data
        this.loading = false
    }
  }
}
</script>

<style lang="less">
#data-view {
  width: 100%;
  height: 100%;
  background-color: #030409;
  color: #fff;

  #dv-full-screen-container {
    background-image: url('./img/bg.png');
    background-size: 100% 100%;
    box-shadow: 0 0 3px blue;
    display: flex;
    flex-direction: column;
  }

  .main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .block-left-right-content {
    flex: 1;
    display: flex;
    margin-top: 20px;
  }

  .block-top-bottom-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-left: 20px;
  }

  .block-top-content {
    height: 55%;
    display: flex;
    flex-grow: 0;
    box-sizing: border-box;
    padding-bottom: 20px;
  }
}
#rose-chart {
  width: 70%;
  height: 100%;
  background-color: rgba(6, 30, 93, 0.5);
  border-top: 2px solid rgba(1, 153, 209, .5);
  box-sizing: border-box;

  .rose-chart-title {
    height: 50px;
    font-weight: bold;
    text-indent: 20px;
    font-size: 20px;
    display: flex;
    align-items: center;
  }

  .dv-charts-container {
    height: calc(~"100% - 50px");
  }
}
</style>
