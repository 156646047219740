<template>
  <div id="scroll-board">
    <dv-scroll-board :config="config" />
  </div>
</template>

<script>
export default {
  name: 'ScrollBoard',
  props:{
    boardData:Object
  },
  data () {
    return {
      config: {
        header: [],
        data: [],
        index: false,
        // columnWidth: [50, 170, 300],
        align: ['center','center','center','center'],
        rowNum: 10,
        headerBGC: '#1981f6',
        headerHeight: 45,
        oddRowBGC: 'rgba(0, 44, 81, 0.8)',
        evenRowBGC: 'rgba(10, 29, 50, 0.8)'
      }
    }
  },
  watch:{
    boardData:function(newData){
      this.config= {
        header: newData.scrollBoard.header,
        data: newData.scrollBoard.data,
        index: false,
        // columnWidth: [50, 170, 300],
        align: ['center','center','center','center'],
        rowNum: 10,
        headerBGC: '#1981f6',
        headerHeight: 45,
        oddRowBGC: 'rgba(0, 44, 81, 0.8)',
        evenRowBGC: 'rgba(10, 29, 50, 0.8)'
      }
    }
  },
  mounted(){
    this.config.header= this.boardData.scrollBoard.header
    this.config.data= this.boardData.scrollBoard.data
  }
}
</script>

<style lang="less">
#scroll-board {
  width: 50%;
  box-sizing: border-box;
  margin-left: 20px;
  height: 100%;
  overflow: hidden;
}
</style>
